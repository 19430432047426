import React, {useEffect, useState} from "react";
import {
    AppBar,
    Card,
    CardContent, CardHeader,
    Chip, CircularProgress,
    Grid,
    IconButton,
    ListItem, ListItemButton, ListItemText,
    Paper,
    TextField,
    Toolbar,
    useTheme
} from "@mui/material";
import {IoCopy} from "react-icons/io5";

import {ICustomerType, IEmployee, IFacility, IInspection} from "../../rest-data-provider/bfaTypes";
import {DateRangeField} from "../../components/custom/daterange/DateRangeField";
import {HttpError, useApiUrl, useCustom, useList} from "@refinedev/core";
import {Close} from "@mui/icons-material";
import {Autocomplete} from "@mui/material";
import {v4 as uuidv4} from 'uuid';
import {IDashboardCustomer, IDashboardLocation, IDashboardParameters, IDashboardStatistics} from "./DashboardTypes";
import {DateRange} from "mui-daterange-picker-plus";
import {DashboardCard} from "./DashboardCard";
import {BsListCheck} from "react-icons/bs";
import {MdElectricalServices} from "react-icons/md";
import {FaPerson} from "react-icons/fa6";

interface DashboardPanelProps {
    onDuplicatePanel: () => void
    onDeletePanel: () => void
    id: number
}
export const DashboardPanel: React.FC<DashboardPanelProps> = ({ id, onDuplicatePanel, onDeletePanel}) => {

    const [selectedEmployees, setSelectedEmployees] = useState<IEmployee[]>([])

    const [allLocations, setAllLocations] = useState<IDashboardLocation[]>([])
    const [selectedLocations, setSelectedLocations] = useState<IDashboardLocation[]>([])

    const [filteredInspections, setFilteredInspections] = useState<number[]>([])
    const [filteredEmployees, setFilteredEmployees] = useState<number[]>([])
    const [selectedDataRange, setSelectedDataRange] = useState<DateRange>({startDate: undefined, endDate: undefined})
    
    const theme = useTheme();
    const apiUrl = useApiUrl();

    const { data: allEmployees, isLoading: isLoadingEmployees, isError: isErrorEmployees } = useList<IEmployee, HttpError>({
        resource: "employees",
        pagination: {
            mode: "off",
        },
    });

    const { data: allCustomers, isLoading: isLoadingCustomers, isError: isErrorCustomers } = useCustom<IDashboardCustomer[], HttpError>({
        url: apiUrl + `/dashboard/customers`,
        method: 'get',
    });

    const { data: statistics,isLoading: isLoadingStatistics,isError: isErrorStatistics } = useCustom<IDashboardStatistics, HttpError>({
        url: apiUrl + `/dashboard/statistics`,
        method: 'post',
        config: {
            payload: {
                inspections: filteredInspections,
                employees: filteredEmployees,
                from: selectedDataRange.startDate,
                to: selectedDataRange.endDate,
            } as IDashboardParameters,
        },
        queryOptions: {
            enabled: filteredInspections.length > 0,
            retry: false,
            keepPreviousData: false,
            cacheTime: 0
        }
    });

    useEffect(() => {
        setFilteredEmployees(selectedEmployees.map((employee: IEmployee) => employee.id));
    }, [selectedEmployees]);

    useEffect(() => {
        if(allLocations) {
            const inspections = []
            if(selectedLocations.length > 0) {
                for (const location of selectedLocations) {
                    inspections.push(...location.inspections)
                }
            } else {
                for (const location of allLocations) {
                    inspections.push(...location.inspections)
                }
            }
            setFilteredInspections(inspections)
        } else {
            setFilteredInspections([])
        }
    }, [selectedLocations, allLocations]);

    useEffect(() => {
        if(allCustomers && allCustomers.data && allCustomers.data.length > 0) {
            const locations : IDashboardLocation[] = [];
            const sortedCustomers = allCustomers.data.sort((a, b) => {
                return a.customer.name.localeCompare(b.customer.name);
            })
            for (const customer  of sortedCustomers) {
                if(customer.facilities.length > 1) {
                    locations.push({
                        id: customer.customer.id,
                        name: `Alle Einrichtungen von ${customer.customer.name}`,
                        parentName: customer.customer.name,
                        inspections: customer.inspections,
                        isCustomer: true
                    });
                } else {
                    const facility = customer.facilities[0].facility
                    locations.push({
                        id: facility.id,
                        name: customer.customer.name,
                        address: `${facility.street} ${facility.houseNumber}, ${facility.zipCode} ${facility.city}`,
                        parentName: customer.customer.name,
                        inspections: customer.inspections,
                        isCustomer: false
                    });
                }

                console.log('customer.facilities', customer.facilities)
                if(customer.facilities.length > 1) {
                    for (const facility of customer.facilities) {
                        const facilityName = facility.facility.name ? `${facility.facility.name}` : '?';
                        locations.push({
                            id: facility.facility.id,
                            name: `${facilityName}`,
                            address: `${facility.facility.street} ${facility.facility.houseNumber}, ${facility.facility.zipCode} ${facility.facility.city}`,
                            inspections: facility.inspections,
                            isCustomer: false,
                            parentName: customer.customer.name
                        });
                    }
                }
            }
            setAllLocations(locations);
        }
    }, [allCustomers]);

    return (
        <Card style={{overflow: 'visible'}}>
            <AppBar position="static">
                <Toolbar style={{backgroundColor: '#eeeaea', position: 'relative', overflow: 'visible', padding: '10px'}}>
                    <IconButton onClick={() => {
                        if(id === 0) {
                            onDuplicatePanel()
                        } else {
                            onDeletePanel();
                        }
                    }} style={{position: 'absolute', top: '-13px', right:'-13px', backgroundColor: id == 0 ? theme.palette.primary.main : theme.palette.secondary.main, color: 'white'}}>
                        {id === 0 ? <IoCopy fontSize={'20'} /> : < Close />}</IconButton>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper>
                                <Autocomplete multiple value={selectedLocations}  onChange={(event, value, reason, details) => {
                                    // setSelectedFacilities(value);
                                    setSelectedLocations(value)
                                }}  getOptionLabel={(option : IDashboardLocation) => `${option.name}` /*(${option.street} ${option.houseNumber}, ${option.zipCode} ${option.city})`*/}
                                              fullWidth renderInput={(params: any) => {
                                    return <TextField inputProps={{
                                        inputMode: 'search'
                                    }} label={'Einrichtungen'} variant={'outlined'} {...params} size={'small'} />
                                }}
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option :IDashboardLocation, index) => {
                                          const { key, ...tagProps } = getTagProps({ index });
                                          return (
                                              <Chip size={'small'}
                                                    key={key}
                                                    label={`${option.name} (${option.address})`}
                                                    {...tagProps}
                                              />
                                          );
                                      })
                                    }
                                    groupBy={option => option.parentName }
                                    renderOption={(props, option) => {
                                      if (option.isCustomer) {
                                          return <li {...props}
                                                     key={`facility-option-customer-${option.id}`}>
                                              <b style={{color: 'grey'}}>{option.name}</b>
                                          </li>
                                      }
                                      return <li {...props}
                                                       key={`facility-option-facility-${option.id}`}>
                                          {option.name}&nbsp;<small style={{color:'grey'}}><i> - {option.address}</i></small>
                                      </li>
                                    }}
                                    options={allLocations ? allLocations : []}
                                    filterOptions={(options, state) => {
                                      console.log(state.inputValue);
                                      const newOptions = options.filter(value => {
                                          if(value.name && value.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) !== -1) {
                                              return value;
                                          }
                                          if(value.parentName && value.parentName.toLowerCase().indexOf(state.inputValue.toLowerCase()) !== -1) {
                                              return value;
                                          }
                                      })
                                      return newOptions;
                                    }}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper>
                                <Autocomplete multiple value={selectedEmployees}  onChange={(event, value, reason, details) => {
                                    setSelectedEmployees(value);
                                }}  getOptionLabel={(option : IEmployee) => `${option.firstName} ${option.lastName}`}
                                              fullWidth renderInput={(params: any) => {
                                    return <TextField label={'Mitarbeiter'} variant={'outlined'} {...params} size={'small'} />
                                }}
                                      renderTags={(tagValue, getTagProps) =>
                                          tagValue.map((option :IEmployee, index) => {
                                              const { key, ...tagProps } = getTagProps({ index });
                                              return (
                                                  <Chip size={'small'}
                                                        key={key}
                                                        label={`${option.firstName.substring(0,1)}. ${option.lastName}`}
                                                        {...tagProps}
                                                  />
                                              );
                                          })
                                      }
                                      options={allEmployees ? allEmployees.data : []} />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper>
                                <DateRangeField fullWidth didSelectDateRange={range => {
                                    setSelectedDataRange(range)
                                }}></DateRangeField>
                            </Paper>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
                <Grid container>
                    {isLoadingStatistics && (
                        <Grid item xs={12} style={{display:'flex', placeContent: 'center', placeItems:'center', minHeight: '200px'}}>
                            {/*<h2>Hier werden später die Statistiken angezeigt</h2>*/}
                            {/*<p>Folgende Prüfungen werden für die Auswertung herangezogen:</p>*/}
                            {/*<ul>*/}
                            {/*    {filteredInspections?.map(value => <li key={`inspection-${value}`}>{value}</li>)}*/}
                            {/*</ul>*/}

                            <CircularProgress size={'60px'}></CircularProgress>

                        </Grid>
                    )}
                    <Grid item xs={12} style={{padding: '30px'}}>
                        {!isLoadingStatistics && statistics && (
                            <>
                                {/*<h2>Ergebnisse</h2>*/}
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <DashboardCard title={`${statistics.data.inspections.length.toLocaleString()}`}
                                                       subtitle={'Durchgeführte Prüfungen'}
                                                       icon={<BsListCheck size={'90px'}></BsListCheck>}></DashboardCard>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DashboardCard title={`${statistics.data.inspectionItems.length.toLocaleString()}`}
                                                       subtitle={'Geprüfte Geräte'}
                                                       icon={<MdElectricalServices size={'90px'}></MdElectricalServices>}></DashboardCard>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DashboardCard title={`${statistics.data.employees.length.toLocaleString()}`}
                                                       subtitle={'Aktive Prüfer'}
                                                       icon={<FaPerson style={{paddingTop: '10px'}} size={'75px'}></FaPerson>}></DashboardCard>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>

        </Card>
    )
}