import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import {CalendarIcon, DEFAULT_LOCALE} from "@mui/x-date-pickers";
import { de } from 'date-fns/locale';

import {DateRange, PickerModal} from "mui-daterange-picker-plus";
import {format, getDaysInMonth, getMonth, getYear, isSameDay, isSameMonth, isSameYear, subDays} from "date-fns";

interface DateRangeFieldProps {
    fullWidth?: boolean;
    didSelectDateRange: (range: DateRange) => void
}



export const DateRangeField : React.FC<DateRangeFieldProps> = ({fullWidth, didSelectDateRange}) => {

    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()

    const [formattedDate, setFormattedDate] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setStartDate(new Date(getYear(new Date()), getMonth(new Date())-1, 1)),
        setEndDate(new Date(getYear(new Date()), getMonth(new Date())-1, getDaysInMonth(new Date(getYear(new Date()), getMonth(new Date())-1))));
    }, []);

    useEffect(() => {
        didSelectDateRange({
            startDate: startDate,
            endDate: endDate,
        })
    }, [startDate, endDate]);

    useEffect(() => {
        if(startDate && endDate) {
            if(isSameDay(startDate, endDate)) {
                setFormattedDate(`${format(endDate, 'dd.MM.yyyy')}`);
            } else if(isSameMonth(startDate, endDate)) {
                setFormattedDate(`${format(startDate, 'dd.')} - ${format(endDate, 'dd.MM.yyyy')}`);
            } else if (isSameYear(startDate, endDate)) {
                setFormattedDate(`${format(startDate, 'dd.MM.')} - ${format(endDate, 'dd.MM.yyyy')}`);
            } else {
                setFormattedDate(`${format(startDate, 'dd.MM.yyyy')} - ${format(endDate, 'dd.MM.yyyy')}`);
            }

        }
    }, [startDate, endDate]);

    const handleSelect = (range: DateRange) => {
        setStartDate(range.startDate);
        setEndDate(range.endDate);
        setDialogOpen(false)
    }
    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    return (
        <>
            <TextField placeholder={'Zeitraum'} label={'Zeitraum'} variant={'outlined'} size={'small'} fullWidth={!!fullWidth} InputProps={{
                endAdornment:<IconButton onClick={(event) => {
                    setDialogOpen(true)
                    setAnchorEl(event.currentTarget)
                }}><CalendarIcon color={'primary'}></CalendarIcon></IconButton>
            }} value={formattedDate} inputProps={{readOnly: true}} onClick={(event) => {
                setDialogOpen(true)
                setAnchorEl(event.currentTarget)
            }}></TextField>
            {/*<Dialog open={true}>*/}
            {/*    <DialogTitle>Datum auswählen</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <DateRangePicker*/}
            {/*            ranges={[selectionRange]}*/}
            {/*            onChange={handleSelect}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
            <PickerModal
                locale={de}

                onChange={(range: DateRange) => handleSelect(range)}
                customProps={{
                     onSubmit: (range: DateRange) => handleSelect(range),
                     onCloseCallback: handleDialogClose,
                }}
                labels={{
                    actions: {
                        apply: 'Übernehmen',
                        cancel: 'Abbrechen'
                    },
                    footer: {
                        startDate: 'Datum Von',
                        endDate: 'Datum Bis'
                    },
                }}
                definedRanges={[
                    {
                        label:'Heute',
                        startDate: new Date(),
                        endDate: new Date(),
                    },
                    {
                        label:'Gestern',
                        startDate: subDays(new Date(), 1),
                        endDate: subDays(new Date(), 1),
                    },
                    {
                        label:'Aktueller Monat',
                        startDate: new Date(getYear(new Date()), getMonth(new Date()), 1),
                        endDate: new Date(getYear(new Date()), getMonth(new Date()), getDaysInMonth(new Date(getYear(new Date()), getMonth(new Date())))),
                    },
                    {
                        label:'Letzter Monat',
                        startDate: new Date(getYear(new Date()), getMonth(new Date())-1, 1),
                        endDate: new Date(getYear(new Date()), getMonth(new Date())-1, getDaysInMonth(new Date(getYear(new Date()), getMonth(new Date())-1))),
                    },
                    {
                        label:'Aktuelles Jahr',
                        startDate: new Date(getYear(new Date()), 0, 1),
                        endDate: new Date(getYear(new Date()), 11, 31),
                    },
                    {
                        label:'Letztes Jahr',
                        startDate: new Date(getYear(new Date())-1, 0, 1),
                        endDate: new Date(getYear(new Date())-1, 11, 31),
                    }

                ]}
                modalProps={{
                    open: dialogOpen,
                    anchorEl,
                    onClose: handleDialogClose,
                    slotProps: {
                        paper: {
                            sx: {
                                borderRadius: "16px",
                                boxShadow: "rgba(0, 0, 0, 0.21) 0px 0px 4px",
                            },
                        },
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                }}
            />
        </>

    )
}