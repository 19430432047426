import React from "react";
import {Card, CardHeader, useTheme} from "@mui/material";
import {BsListCheck} from "react-icons/bs";

interface DashboardCardProps {
    title: string
    subtitle: string
    icon: React.ReactElement
}
export const DashboardCard : React.FC<DashboardCardProps> = ({title, subtitle, icon}) => {

    const theme = useTheme();

    return (
        <Card elevation={4} sx={{border: `1px solid ${theme.palette.primary.main}`, borderRadius:'10px' , position: 'relative',
            '& .MuiCardHeader-subheader': { color: 'grey', fontSize: '18px' },
            '& .MuiCardHeader-title': {fontSize: '40px', fontWeight:'bold'}
        }}>
            <CardHeader title={title} subheader={subtitle}></CardHeader>
            <div style={{position: 'absolute', top: '10px', right: '10px',
                color: theme.palette.primary.main, transform: 'rotate(0deg)'
            }}>
                {icon}
            </div>

        </Card>
    )
}